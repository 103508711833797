import './style.css';
import React from 'react';
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCreditCard} from '@fortawesome/free-solid-svg-icons'; 
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

class App extends React.Component {
  
  componentDidMount() {
    $(window).scroll( function(e){
      parallax();
    });
    $('#mainImg').animate( {opacity: 1}, 2000);
    function parallax(){		
      var scrolled = $(window).scrollTop();
      	$('.Background').css('background-position-y',-(scrolled*0.7)+'px');
    }
  }

  render(){
    return (
<div className='Background'>
    <div className='TopNav'>
      <ul>
        <li><a href='#fanmeeting2022'>ご案内</a></li>
        <li><a href='https://info2.y-tickets.jp/order/subscription_sc/00005335/sns' target='_blank' >チケット<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a></li>
        <li><a href='https://info2.y-tickets.jp/hall/index/102307' target='_blank' >アクセス<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a></li>
        <Iconset></Iconset>
      </ul>
    </div>
    <div className='BodySection'>
      <img src={ require('./img/topImg.jpg') } className='Image' id='mainImg' />
      <div className='TextBox TextCenter' id='fanmeeting2022'>
        <div className='TitleBox borderBottomPink'>
          <h1 className='titleName'>ハン スンヨン</h1><h3 className='titleSub'>Han Seung Yeon</h3>
          <h1 className='titleSub'>15周年ファンミーティング 2022</h1>
        </div>
        <div>
          <ArticleText></ArticleText>
          <table className='infoTable'>
            <tr>
              <th>日 程</th>
              <td>2022年 10月 23日(日)<br/>
		              1部	開場13:00／開演14:00<br/>
		              2部	開場17:00／開演18:00<br/>
              </td>
            </tr>
            <tr>
              <th>会 場</th>
              <td>品川インターシティホール　<a href='https://info2.y-tickets.jp/hall/index/102307' target='_blank' className='btn'>アクセス<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a>
              </td>
            </tr>
            <tr>
              <th>チケット</th>
              <td>11,000円（税込）<br/>
	            当日 12,000円（税込）<span className='breakMB'/>※発生した場合のみ　<a href='https://info2.y-tickets.jp/order/subscription_sc/00005335/sns' target='_blank' className='btn'>購入先<FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a>
              <br/>
              <strong>※まだ多少の当日券がございます。チケットは、当日、会場で12,000円で購入できます。</strong>
              </td>
            </tr>
            <tr>
              <th>プレイガイド</th>
              <td>SNS先行 : 10月 1日（土）<span className='breakMB'/>正午より先行受付開始<br/>
              一般発売 : 10月14日（金）<span className='breakMB'/>午前10時より販売予定<br/>
              チケットぴあ、ローソンチケットにて販売
              </td>
            </tr>
            <tr>
              <th>問い合わせ</th>
              <td>Han SeungYeon FANMEETING 事務局<br/>
              電話 03-3263-7431（月〜金 午前10時~18時）<br/>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <PhotoSection></PhotoSection>
    </div>
    <Footer></Footer>
    <Float></Float>
</div>
  );
}
}

function Float() {
  return(
    <div>
      <a className='Floating' href='https://info2.y-tickets.jp/order/subscription_sc/00005335/sns'><FontAwesomeIcon icon={ faCreditCard } />　チケットはこちらへ</a>
    </div>
  )
}

function PhotoSection() {
  return(
    <div className='photosection'>
      <img src={ require('./img/hsy01.jpg') } />
      <img src={ require('./img/hsy02.jpg') } />
      <img src={ require('./img/hsy03.jpg') } />
      <img src={ require('./img/hsy04.jpg') } />
    </div>
  )
}
function Iconset() {
  return(
    <div className='RightIcon'>
      <a href='https://www.instagram.com/thesy88' target='_blank'><FontAwesomeIcon icon={ faInstagram } /></a>
      <a href='https://twitter.com/fateflysy' target='_blank'><FontAwesomeIcon icon={ faTwitter } /></a>
    </div>
  )
}
function ArticleText() {
  return(
    <div className='articleText'>
      日本のファン待望のKARAのスンヨンの、緊急来日が決定しました！<br/>
      KARA結成15周年を記念して、先日再結成を発表したばかりですが、全員揃っての来日は来年になりますが、その前にいち早くスンヨンが3年ぶりにファンの皆さんに会いに来ます！<br/>
      スンヨンも、ファンの皆さんとの待望の交流を心から待ち望んでいますので会い来て下さい。<br/>
      10月23日(日)は品川インターシティホールに集まって、再結成のお祝いに駆け付けましょう！<br/>
      <ul>
        <li>1部2部両方チケットを購入の方にリハーサル観覧招待<br/>※指定の座席で1部2部どちか希望の部で実施予定</li>
        <li>公演中のフォトタイムを実施<br/>※詳細に付いては後日発表</li>
        <li>各回につき抽選してサイン入りポスターを贈呈。</li>
        <li>スペシャル企画:事前に各回グッズ限定セットをお買い上げの方に抽選でスンヨンと2ショット撮影権利(限定セットの数量が無くなり次第終了)<br/>※グッズセットの詳細は後日発表致します</li>
      </ul>
      <span className='noticeText'>
        <h2 className='TextCenter'>特典案内</h2><br/>
        <span className='TextCenter TextLeft_MB'>
        両方のチケットをご購入の方でリハーサル見学ご希望の方は、<span className='breakLine'/>
        12時30分までに、会場にきていただき、特典当選者受付テーブルで、<span className='breakLine'/>
        1部、2部のチケットを持ってきてください。<br/>
        <br/>
        </span>
        <span className='pinkText'>
        ① 各回につき抽選してサイン入りポスターを公演終了後ポスター購入して頂いた方には、<span className='breakLine'/>
        全員、帰りにスンヨンより直接受け取れる。(数量が無くなり次第終了)
        <br/><br/>
        ② スペシャル企画:事前に各回グッズ限定セットをお買い上げの方に抽選でスンヨンと2ショット撮影権利(限定セットの数量が無くなり次第終了)<span className='breakLine'/>
        <br/>
        ※詳細はそちらでご案内致します<br/>
        ※リハーサル見学は、1部開場前だけになりますので予めご了承ください。<br/>
        <a href={ require('./img/goods_hsy2022.pdf') } target="_blank" className='goodsBtn'>グッズ販売のご案内</a>
        </span>
      </span>
    </div>
  )
}

function Footer(){
  return(
    <>
    <footer>
      <div className='footerTop'></div>
      <div className='footerBottom'>
        <span>ハン スンヨン 15周年ファンミーティング 2022</span>
        <table className='footerInfo'>
          <tr>
            <th>主催・企画</th>
            <td>(株)HUB JAPAN、(株)STARPICK JAPAN</td>
          </tr>
          <tr>
            <th>主管・制作</th>
            <td>(株)サイドエー</td>
          </tr>
          <tr>
            <th>協 力</th>
            <td>(株)YG Entertainment、(株)LOOK KOREA TOUR、(株)テンプリント、エイベックス・エンタテインメント(株)</td>
          </tr>
          <tr>
            <th>音源協力</th>
            <td>(株)第一興商</td>
          </tr>
        </table>
      </div>
    </footer> 
    </>
  )
}

export default App;
 
